import type React from 'react';
import clsx from 'clsx';

interface ErrorMessageProps {
  children: React.ReactNode;
  className?: string;
}

const PtnErrorMessage: React.FC<ErrorMessageProps> = ({
  children,
  className = '',
}) => {
  return <p className={clsx('text-12 text-red-30', className)}>{children}</p>;
};

interface ErrorMessagesProps {
  children: React.ReactNode;
  id?: string;
  name: string;
  isShown?: boolean;
  errors?: string[];
  className?: string;
}

export const PtnErrorMessages: React.FC<ErrorMessagesProps> = ({
  children,
  name,
  errors = [],
  isShown = true, // conform で制御する場合は自前制御不要のため、デフォルト true にしておく
  className = '',
}) => {
  return (
    <div className={clsx('text-12', className)}>
      {children}
      <div className="flex flex-col space-y-1 pl-0.5">
        {isShown &&
          errors.length > 0 &&
          errors.map((error, index) => {
            return (
              <PtnErrorMessage className="first:mt-1" key={`${name}_${index}`}>
                {error}
              </PtnErrorMessage>
            );
          })}
      </div>
    </div>
  );
};
